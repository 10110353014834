exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-flaskapp-js": () => import("./../../../src/pages/portfolio/flaskapp.js" /* webpackChunkName: "component---src-pages-portfolio-flaskapp-js" */),
  "component---src-pages-portfolio-gatsbyapp-js": () => import("./../../../src/pages/portfolio/gatsbyapp.js" /* webpackChunkName: "component---src-pages-portfolio-gatsbyapp-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-reactapp-js": () => import("./../../../src/pages/portfolio/reactapp.js" /* webpackChunkName: "component---src-pages-portfolio-reactapp-js" */),
  "component---src-pages-portfolio-v-2-fridge-jsx": () => import("./../../../src/pages/portfolioV2/Fridge.jsx" /* webpackChunkName: "component---src-pages-portfolio-v-2-fridge-jsx" */),
  "component---src-pages-portfolio-v-2-glum-crm-jsx": () => import("./../../../src/pages/portfolioV2/glumCRM.jsx" /* webpackChunkName: "component---src-pages-portfolio-v-2-glum-crm-jsx" */),
  "component---src-pages-portfolio-v-2-misetta-jsx": () => import("./../../../src/pages/portfolioV2/misetta.jsx" /* webpackChunkName: "component---src-pages-portfolio-v-2-misetta-jsx" */),
  "component---src-pages-portfolio-v-2-project-template-jsx": () => import("./../../../src/pages/portfolioV2/projectTemplate.jsx" /* webpackChunkName: "component---src-pages-portfolio-v-2-project-template-jsx" */),
  "component---src-pages-portfolio-v-2-reccenter-jsx": () => import("./../../../src/pages/portfolioV2/Reccenter.jsx" /* webpackChunkName: "component---src-pages-portfolio-v-2-reccenter-jsx" */),
  "component---src-pages-portfolio-v-2-spitball-jsx": () => import("./../../../src/pages/portfolioV2/Spitball.jsx" /* webpackChunkName: "component---src-pages-portfolio-v-2-spitball-jsx" */),
  "component---src-pages-portfolio-v-2-storyboard-jsx": () => import("./../../../src/pages/portfolioV2/storyboard.jsx" /* webpackChunkName: "component---src-pages-portfolio-v-2-storyboard-jsx" */)
}

